<template>
  <div
    class="content-component row"
    :class="[component.paragraph.type, { 'component-active': isActive }]"
    :style="objectStyles"
    @click="updateCurrentComponent"
  >
    <span class="content-component-count">{{ componentCount }}</span>
    <div class="image-and-text-content">
      <section
        class="images-section column"
        :class="component.paragraph.imagesOrientation"
      >
        <div
          v-for="(image, index) in component.paragraph.images"
          :key="index"
          class="image-section"
        >
          <image-upload
            :component="image"
            class="image-displayed"
            :remove="removeImageDetails"
            :options="options"
          />
        </div>
        <div
          v-if="component.paragraph.images.length < 1"
          class="image-section column"
        >
          <image-upload
            :component="{ imageId: null }"
            :options="options"
            :add="newImageDetails"
          />
        </div>
      </section>

      <div class="image-and-text-content-text-content">
        <ckeditor
          :editor="editor"
          :placeholder="$t('textPlaceHolder')"
          v-model="content"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";

export default {
  name: "ParagraphComponent",
  mixins: [BackOfficeComponentMixin],
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    content: {
      get() {
        return this.component.paragraph.content[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "content", this.locale);
      },
    },
    objectStyles() {
      return {
        order: this.component.order,
      };
    },
  },
  props: {
    componentCount: Number,
  },
};
</script>

<style >
.image-and-text-content {
  /* margin-top: 30px; */
  display: flex;
  flex-wrap: wrap;
}
.image-and-text-content-text-content {
  max-width: 458px !important;
  margin-top: 30px;
  height: auto !important;
}
</style>