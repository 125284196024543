var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component row",
      class: [
        _vm.component.paragraph.type,
        { "component-active": _vm.isActive }
      ],
      style: { order: _vm.component.order },
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("span", { staticClass: "content-component-count" }, [
        _vm._v(_vm._s(_vm.componentCount))
      ]),
      _c("div", { staticStyle: { display: "flex", width: "100%" } }, [
        _c(
          "div",
          { staticClass: "image-section column" },
          [
            _vm.image
              ? _c("image-upload", {
                  key: _vm.image.id,
                  staticClass: "image-displayed",
                  attrs: {
                    component: _vm.image,
                    remove: _vm.removeImageDetails,
                    options: _vm.options
                  }
                })
              : _c("image-upload", {
                  attrs: {
                    component: {},
                    add: _vm.newImageDetails,
                    options: _vm.options
                  }
                })
          ],
          1
        ),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title"
            }
          ],
          staticClass: "title",
          attrs: { type: "text", placeholder: _vm.$t("titlePlaceHolder") },
          domProps: { value: _vm.title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.title = $event.target.value
            }
          }
        })
      ]),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: {
            click: function($event) {
              _vm.deleteComponent(_vm.component)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("delete")))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }