<template>
  <div
    class="content-component column"
    :class="[{ 'component-active': isActive }, component.paragraph.type]"
    :style="{ order: component.order }"
    @click="updateCurrentComponent"
  >
    <draggable
      v-model="points"
      :options="dragOptions"
      :move="onMove"
      @change="onChange"
    >
    <span class="content-component-count">{{ componentCount }}</span>
      <transition-group>
        <point v-for="point in points" :key="point.id" :component="point" />
      </transition-group>
    </draggable>
    <button class="btn secondary round new-point" @click="addPoint">
      {{ $t("addMapStepText") }}
    </button>
    
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import MapPoint from "@/components/BackOffice/ContentComponents/MapPoint.vue";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";
import draggable from "vuedraggable";
export default {
  name: "InteractiveMapComponent",
  mixins: [BackOfficeComponentMixin],
  components: {
    point: MapPoint,
    draggable,
    CheckBox,
  },
  computed: {
    points: {
      get() {
        return this.component.paragraph.routePoints;
      },
      set(value) {
        this.$store.commit("updateRoutePoints", {
          component: this.component,
          points: value,
        });
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "points",
        ghostClass: "ghost",
        handle: ".btn-drag",
      };
    },
    horizontalMode() {
      return this.component.isHorizontalMode;
    },
  },
  props: {
    componentCount: Number,
  },
  methods: {
    addPoint() {
      this.$store.dispatch("addRoutePoint", this.component);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onChange({ moved }) {
      this.$store.dispatch("updateRoutePointOrder", moved.element);
      this.$store.commit("sortRoutePoints", {
        component: this.component,
        points: this.points,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content-component.interactive-map {
  min-height: 189px;
  padding-top: 56px;
  .new-point {
    margin: auto;
  }
  .content-component-count {
    // margin-top: -32px;
    top: 17px;
  }
  .checkbox-setter {
    top: 4px;
    left: 0;
    transform: translate(0, 0);
  }
  > div:nth-child(2) {
    margin-bottom: 8px;
  }
  .ghost {
    box-sizing: border-box;
    max-height: unset;
    border: none;
    margin: inherit;
    margin-top: 8px;
    height: 72px;
    img {
      display: initial;
    }
  }
  .row.centered {
    z-index: 3;
    top: 8px;
    left: 8px;
    transform: translate(0);
    .checkbox-setter {
      max-width: 125px;
    }
  }
}
</style>
