var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component",
      class: { "component-active": _vm.isActive },
      style: { order: _vm.component.order },
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("span", { staticClass: "content-component-count" }, [
        _vm._v(_vm._s(_vm.componentCount))
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("ckeditor", {
            attrs: {
              editor: _vm.editor,
              placeholder: _vm.$t("textPlaceHolder"),
              config: _vm.editorConfig
            },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        ],
        1
      ),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: {
            click: function($event) {
              _vm.deleteComponent(_vm.component)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("delete")) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }