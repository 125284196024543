<template>
  <div>
    <template v-if="imageExists">
      <div class="image-section">
        <image-upload
          :component="{ imageId: getImageId(0) }"
          class="image-displayed"
          :remove="removeImage"
          :update="updateImage"
          :options="imageOptions"
        />
      </div>
    </template>
    <template v-else>
      <div class="image-section column">
        <image-upload
          :component="{ imageId: null }"
          :add="addImage"
          :options="imageOptions"
        />
      </div>
    </template>
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
export default {
  name: "GlobalConnectionsImageUpload",
  mixins: [ComponentSetterMixin],
  components: { ImageUpload },
  data() {
    return {};
  },
  computed: {
    imageOptions() {
      return { name: "image-upload", text: "addImageText", model: "imageId" };
    },
  },
  props: {
    component: Object,
    parentComponent: Object,
    imageExists: Number,
  },
  methods: {
    addImage(imageId) {
      this.$store.commit("addImageIdToRoutePoint", {
        point: this.component,
        imageId: imageId,
      });
    },
    removeImage(imageId) {
      this.$store.commit("removeImageIdToRoutePoint", {
        point: this.component,
        imageId: imageId,
      });
    },
    updateImage(oldImgId, newImgId) {
      this.$store.commit("updateImageIdToRoutePoint", {
        point: this.component,
        oldImgId: oldImgId,
        newImgId: newImgId,
      });
    },
    getImageId(index) {
      return this.component.images[index];
    },
  },
  mounted() {
    this.$nextTick(function () {});
  },
};
</script>
<style lang="less" scoped>
.content-component.global-connections {
  min-height: 189px;
  padding-top: 56px;
  .new-point {
    margin: auto;
  }
  .content-component-count {
    margin-top: -32px;
  }
  .checkbox-setter {
    top: 4px;
    left: 0;
    transform: translate(0, 0);
  }
  > div:nth-child(2) {
    margin-bottom: 8px;
  }
  .ghost {
    box-sizing: border-box;
    max-height: unset;
    border: none;
    margin: inherit;
    margin-top: 8px;
    height: 72px;
    img {
      display: initial;
    }
  }
  .row.centered {
    z-index: 3;
    top: 8px;
    left: 8px;
    transform: translate(0);
    .checkbox-setter {
      max-width: 125px;
    }
  }
  //css added by animesh
  .flex-container {
    // margin-top: 10px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    .flex-child {
      flex: 1;
      .title {
        min-width: 657px;
        margin-top: 0px;
        margin-left: 0px;
      }
    }
    .flex-child:first-child {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .image-section {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin: auto;
      width: 320px;
      height: auto;
    }
  }
}
</style>
