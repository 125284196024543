<template>
  <div
    class="content-component row"
    :class="[component.paragraph.type, { 'component-active': isActive }]"
    :style="{ order: component.order }"
    @click="updateCurrentComponent"
  >
    <span class="content-component-count">{{ componentCount }}</span>
    <div class="column">
      <input
        type="text"
        class="title"
        v-model="title"
        :placeholder="$t('titlePlaceHolder')"
      />
      <video-url-setter
        :component="component"
        :options="urlComponentProperty"
      />
    </div>
    <video-player
      v-if="isVideoUrlValid"
      :key="videoUrl[locale]"
      :thumbnail="thumbnail"
      :url="videoUrl[locale]"
      :width="168"
      :height="105"
    />
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import VideoUrlSetter from "@/components/BackOffice/ComponentSetters/VideoUrlSetter.vue";
import VideoPlayer from "@/common/VideoPlayer.vue";
export default {
  name: "VideoContentComponent",
  mixins: [BackOfficeComponentMixin],
  components: {
    VideoPlayer,
    VideoUrlSetter,
  },
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    isParagraph() {
      return this.component && this.component.paragraph ? true : false;
    },
    videoUrl: {
      get() {
        return this.isParagraph
          ? this.component.paragraph.videoUrl
          : this.component.videoUrl;
      },
      set(value) {
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, "videoUrl")
          : this.setComponentPropertyValue(value, "videoUrl");
      },
    },
    title: {
      get() {
        return this.isParagraph
          ? this.component.paragraph.title[this.locale]
          : this.component.title[this.locale];
      },
      set(value) {
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, "title", this.locale)
          : this.setComponentPropertyValue(value, "title", this.locale);
      },
    },
    urlComponentProperty() {
      return this.isParagraph
        ? this.component.paragraph.setters.find(
            (e) => e.name === "video-url-setter"
          )
        : this.component.setters.find((e) => e.name === "video-url-setter");
    },
    isVideoUrlValid() {
      return this.isParagraph
        ? this.component.paragraph.errors.length > 0
          ? false
          : true
        : this.component.errors.length > 0
        ? false
        : true;
    },
    thumbnail() {
      let image = this.isParagraph ?  this.component.paragraph.images[0] : this.component.images[0];
      return image ? image.imageId : "";
    },
  },
  props: {
    componentCount: Number,
  },
};
</script>