<template>
    <div class="video-player">
        <template v-if="!isYoutubeVideo">
            <video ref="video"  :width="width" :height="height"
                :poster="thumbnailUrl"
                @playing="playing" class="video">
                <source :src="url" type="video/mp4">
                <source :src="url" type="video/ogg">
                Your browser does not support the video tag.
            </video>
        </template>
        <template v-else>
            <youtube-media :video-id="YoutubeVideoId" class="video" @playing="playing"
             :player-width="width" :player-height="height">
            </youtube-media>
        </template>
    </div>
</template>
<script>
import getIdFromURL from 'vue-youtube-embed'
import ValidationHelper from '@/utils/ValidationHelper.js'
export default {
    name: 'VideoPlayer',
    components:{
        getIdFromURL, 
        ValidationHelper
    },
    props:{
        id: Number,
        url: String, 
        width: Number, 
        height: Number, 
        thumbnail: {
            required: false
        }
    },
    data(){
        return{
            player:'',
            isVideoPlaying: false
        }
    },
    computed:{
        YoutubeVideoId(){
            return this.$youtube.getIdFromURL(this.url)
        },
        isYoutubeVideo(){
            return ValidationHelper.isYoutubeVideoUrl(this.url)
        }, 
        videoPlaying(){
            return this.$store.state.videoPlaying
        }, 
        thumbnailUrl(){
            
            return this.thumbnail ? this.getImageUrl(this.thumbnail) : ''
        },
    }, 
    methods:{
        ready (event) {
          this.player = event.player
        },
        playing (event) {
            this.player = event.target
            this.updateVideoPlaying()
            this.isVideoPlaying = true
        },
        pause() {
            this.player.pauseVideo 
                ? this.player.pauseVideo() 
                : this.$refs.video.pause()            
        }, 
        updateVideoPlaying(){
            this.$store.commit('updateVideoPlaying', this.id)
        }
    }, 
    watch:{
        videoPlaying: function(value){
            if(value != this.id && this.isVideoPlaying)
            {
                this.pause()
                this.isVideoPlaying = false
            }
        }
    }
}
</script>