var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component column padd-bottom",
      class: [
        { "component-active": _vm.isActive },
        _vm.component.paragraph.type
      ],
      style: { order: _vm.component.order },
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("div", { staticClass: "flex-container" }, [
        _c("div", { staticClass: "flex-child" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title"
              }
            ],
            staticClass: "title",
            attrs: { type: "text", placeholder: _vm.$t("titlePlaceHolder") },
            domProps: { value: _vm.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.title = $event.target.value
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "flex-container" }, [
        _c(
          "div",
          { staticClass: "flex-child" },
          [
            _c("gc-image-upload", {
              attrs: {
                component: _vm.points[0],
                imageExists: _vm.isImageExists(_vm.points[0]),
                parentComponent: _vm.component
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-child" },
          [
            _c("gc-image-upload", {
              attrs: {
                component: _vm.points[1],
                imageExists: _vm.isImageExists(_vm.points[1]),
                parentComponent: _vm.component
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "flex-container" }, [
        _c(
          "div",
          { staticClass: "flex-child" },
          [
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("gc-image-upload", {
                  attrs: {
                    component: _vm.points[2],
                    imageExists: _vm.isImageExists(_vm.points[2]),
                    parentComponent: _vm.component
                  }
                })
              ],
              1
            ),
            _c("point", {
              attrs: { component: _vm.points[2] },
              model: {
                value: _vm.points,
                callback: function($$v) {
                  _vm.points = $$v
                },
                expression: "points"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-child" },
          [
            _c("point", {
              attrs: { component: _vm.points[3] },
              model: {
                value: _vm.points,
                callback: function($$v) {
                  _vm.points = $$v
                },
                expression: "points"
              }
            })
          ],
          1
        )
      ]),
      _c("span", { staticClass: "content-component-count" }, [
        _vm._v(_vm._s(_vm.componentCount))
      ]),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: {
            click: function($event) {
              _vm.deleteComponent(_vm.component)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("delete")) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }