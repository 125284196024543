<template>
  <div
    class="content-component column"
    :class="[component.paragraph.type, { 'component-active': isActive }]"
    :style="objectStyles"
    v-on:click="updateCurrentComponent"
  >
    <span class="content-component-count">{{ componentCount }}</span>
    <check-box
      :component="component"
      class="centered"
      :options="{ text: 'horizontal', model: 'isHorizontalMode' }"
    />
    <section
      class="images-section"
      :class="component.paragraph.isHorizontalMode ? 'row' : 'column'"
    >
      <div
        v-for="(image, index) in component.paragraph.images"
        :key="index"
        class="image-section"
      >
        <image-upload
          :component="image"
          class="image-displayed"
          :remove="removeImageDetails"
          :options="options"
        />
      </div>
      <div v-if="component.paragraph.images.length < 1" class="image-section column">
        <image-upload
          :component="{ imageId: null }"
          :add="newImageDetails"
          :options="options"
        />
      </div>
    </section>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>

<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";

export default {
  name: "OnlyImagesComponent",
  mixins: [BackOfficeComponentMixin],
  components: {
    CheckBox,
  },
  data() {
    return {
      orientation: {
        horizontalMode: false,
      },
    };
  },
  computed: {
    title: {
      get() {
        return this.component.paragraph.title[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "title", this.locale);
      },
    },
    caption: {
      get() {
        return this.component.paragraph.content[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
    objectStyles() {
      return {
        order: this.component.order,
      };
    },
  },
  props: {
    componentCount: Number,
  },
};
</script>

<style lang="less" scoped>
.content-component.only-images {
  position: relative;
  .checkbox-setter {
    top: 4px;
    left: 10px;
    transform: translate(0, 0);
  }
  .images-section {
    margin: 0px auto;
    margin-top: 32px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    .image-section {
      margin-top: 8px;
      margin-right: 8px;
    }
  }
}
</style>

