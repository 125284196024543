var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component row",
      class: [
        _vm.component.paragraph.type,
        { "component-active": _vm.isActive }
      ],
      style: _vm.objectStyles,
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("span", { staticClass: "content-component-count" }, [
        _vm._v(_vm._s(_vm.componentCount))
      ]),
      _c("div", { staticClass: "image-and-text-content" }, [
        _c(
          "section",
          {
            staticClass: "images-section column",
            class: _vm.component.paragraph.imagesOrientation
          },
          [
            _vm._l(_vm.component.paragraph.images, function(image, index) {
              return _c(
                "div",
                { key: index, staticClass: "image-section" },
                [
                  _c("image-upload", {
                    staticClass: "image-displayed",
                    attrs: {
                      component: image,
                      remove: _vm.removeImageDetails,
                      options: _vm.options
                    }
                  })
                ],
                1
              )
            }),
            _vm.component.paragraph.images.length < 1
              ? _c(
                  "div",
                  { staticClass: "image-section column" },
                  [
                    _c("image-upload", {
                      attrs: {
                        component: { imageId: null },
                        options: _vm.options,
                        add: _vm.newImageDetails
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "image-and-text-content-text-content" },
          [
            _c("ckeditor", {
              attrs: {
                editor: _vm.editor,
                placeholder: _vm.$t("textPlaceHolder"),
                config: _vm.editorConfig
              },
              model: {
                value: _vm.content,
                callback: function($$v) {
                  _vm.content = $$v
                },
                expression: "content"
              }
            })
          ],
          1
        )
      ]),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: {
            click: function($event) {
              _vm.deleteComponent(_vm.component)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("delete")) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }