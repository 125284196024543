var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gc-point" },
    [
      _c("ckeditor", {
        staticClass: "content custom-scrollbar",
        attrs: {
          editor: _vm.editor,
          placeholder: _vm.$t("textPlaceHolder"),
          config: _vm.editorConfig
        },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }