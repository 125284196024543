 <template>
  <div
    class="content-component column"
    :class="[{ 'component-active': isActive }, component.paragraph.type]"
    :style="{ order: component.order }"
    @click="updateCurrentComponent"
  >
    <point v-for="point in points" :key="point.id" :component="point" />
    <!-- <draggable  v-model="points" :options="dragOptions" :move="onMove" @change="onChange">
            <transition-group>
              
            </transition-group>
        </draggable> -->
    <button class="btn secondary round new-point" @click="addPoint">
      {{ $t("addLessonStepText") }}
    </button>
    <span class="content-component-count">{{ componentCount }}</span>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import LessonPoint from "@/components/BackOffice/ContentComponents/LessonPoint.vue";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";
import draggable from "vuedraggable";
export default {
  name: "IntractiveLessonComponent",
  mixins: [BackOfficeComponentMixin],
  components: {
    point: LessonPoint,
    draggable,
    CheckBox,
  },
  computed: {
    points: {
      get() {
        return this.component.paragraph.routePoints;
      },
      set(value) {
        this.$store.commit("updateRoutePoints", {
          component: this.component,
          points: value,
        });
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "points",
        ghostClass: "ghost",
      };
    },
    horizontalMode() {
      return this.component.paragraph.isHorizontalMode;
    },
  },
  props: {
    componentCount: Number,
  },
  methods: {
    addPoint() {
      this.$store.dispatch("addRoutePoint", this.component);
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onChange({ moved }) {
      this.$store.dispatch("updateRoutePointOrder", moved.element);
    },
  },
};
</script>
<style lang="less" scoped>
.content-component.interactive-lesson {
  min-height: 189px;
  padding-top: 56px;
  .new-point {
    margin: auto;
    // margin-top: 30px;
  }
  .content-component-count {
    // margin-top: -32px;
    top: 17px;
  }
  .checkbox-setter {
    top: 4px;
    left: 0;
    transform: translate(0, 0);
  }
  > div:nth-child(2) {
    margin-bottom: 8px;
  }
  .ghost {
    box-sizing: border-box;
    max-height: unset;
    border: none;
    margin: inherit;
    margin-top: 8px;
    height: 72px;
    img {
      display: initial;
    }
  }
  .row.centered {
    z-index: 3;
    top: 8px;
    left: 8px;
    transform: translate(0);
    .checkbox-setter {
      max-width: 125px;
    }
  }
  .route {
    // margin-top: -18px;
    margin-bottom: 18px;
  }
}

// .biography-builder {
//   .content-component.interactive-lesson {
//     .new-point {
//       margin-bottom: 33px;
//     }
//   }
// }
</style>
