var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-player" },
    [
      !_vm.isYoutubeVideo
        ? [
            _c(
              "video",
              {
                ref: "video",
                staticClass: "video",
                attrs: {
                  width: _vm.width,
                  height: _vm.height,
                  poster: _vm.thumbnailUrl
                },
                on: { playing: _vm.playing }
              },
              [
                _c("source", { attrs: { src: _vm.url, type: "video/mp4" } }),
                _c("source", { attrs: { src: _vm.url, type: "video/ogg" } }),
                _vm._v(
                  "\n            Your browser does not support the video tag.\n        "
                )
              ]
            )
          ]
        : [
            _c("youtube-media", {
              staticClass: "video",
              attrs: {
                "video-id": _vm.YoutubeVideoId,
                "player-width": _vm.width,
                "player-height": _vm.height
              },
              on: { playing: _vm.playing }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }