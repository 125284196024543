<template>
    <div class="route row ckeditors" >
           <span>{{$t('step')}}  {{component.number}}</span>
    <div class="editor">
        <ckeditor :editor="editor"  :placeholder="$t('textPlaceHolder')" 
                v-model="content" :config="editorConfig"></ckeditor>
    </div>
       </div>    
</template>
<script>
//import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";

export default {
  name: "LessonPoint",
  mixins: [BackOfficeComponentMixin],
  components: {
    ImageUpload,
  },
  computed: {
    content: {
      get() {
        return this.component.content[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
    dragHandlerIcon() {
      return require("@/assets/icons/ic_drag_handler.svg");
    },
  },
};
</script>
<style lang="less">


.route {
  margin-top: 8px;
  min-height: 96px;
  width: 100%;
  display: flex;
  position: relative;
  > span {
    height: 16px;
    flex: 0.1 !important;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    align-self: center;
  }
  .editor{
    flex: 0.9  !important ;
    
  }
  .content {
    height: 72px;
    width: 624px;
    margin-top: 0;
  }
  > img {
    margin-left: 16px;
  }
}
</style>
