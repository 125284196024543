<template>
  <div
    class="content-component row"
    :class="[component.paragraph.type, { 'component-active': isActive }]"
    :style="{ order: component.order }"
    @click="updateCurrentComponent"
  >
    <span class="content-component-count">{{ componentCount }}</span>
    <div class="image-section column">
      <image-upload
        v-if="image"
        :component="image"
        class="image-displayed"
        :remove="removeImageDetails"
        :options="options"
      />
      <image-upload
        v-else
        :component="component"
        :add="newImageDetails"
        :options="options"
      />
    </div>
    <section class="column">
      <input
        type="text"
        class="title"
        v-model="title"
        :placeholder="$t('titlePlaceHolder')"
      />
      <ckeditor
        :editor="editor"
        :placeholder="$t('textPlaceHolder')"
        v-model="content"
        :config="editorConfig"
      ></ckeditor>
    </section>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";

export default {
  name: "CardComponent",
  mixins: [BackOfficeComponentMixin],
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    isParagraph() {
      return this.component && this.component.paragraph ? true : false;
    },
    title: {
      get() {
        return this.isParagraph
          ? this.component.paragraph.title[this.locale]
          : this.component.title[this.locale];
      },
      set(value) {
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, "title", this.locale)
          : this.setComponentPropertyValue(value, "title", this.locale);
      },
    },
    content: {
      get() {
        return this.isParagraph
          ? this.component.paragraph.content[this.locale]
          : this.component.content[this.locale];
      },
      set(value) {
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, "content", this.locale)
          : this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
  },
  props: {
    componentCount: Number,
  },
};
</script>

<style lang="less" scoped>
  .image-section {
    &.column {
      width: 182px;
    }
  }
  .column {
    // width: calc(100%-182px);
    width: calc(100% - 182px);
  }
</style>

