<template>
  <div
    class="content-component"
    :class="{ 'component-active': isActive }"
    :style="{ order: component.order }"
    @click="updateCurrentComponent"
  >
    <span class="content-component-count">{{ componentCount }}</span>
    <div class="mt-5">
    <ckeditor
      :editor="editor"
      :placeholder="$t('textPlaceHolder')"
      v-model="content"
      :config="editorConfig"
    ></ckeditor>
    </div>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";

export default {
  name: "TextContentComponent",
  mixins: [BackOfficeComponentMixin],
  methods: {
    setBuilderComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  computed: {
    isParagraph() {
      return this.component && this.component.paragraph ? true : false;
    },
    content: {
      get() {
        return this.isParagraph
          ? this.component.paragraph.content[this.locale]
          : this.component.content[this.locale];
      },
      set(value) {
        this.isParagraph
          ? this.setBuilderComponentPropertyValue(value, "content", this.locale)
          : this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
  },
  props: {
    componentCount: Number,
  },
};
</script>
