var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.imageExists
        ? [
            _c(
              "div",
              { staticClass: "image-section" },
              [
                _c("image-upload", {
                  staticClass: "image-displayed",
                  attrs: {
                    component: { imageId: _vm.getImageId(0) },
                    remove: _vm.removeImage,
                    update: _vm.updateImage,
                    options: _vm.imageOptions
                  }
                })
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "image-section column" },
              [
                _c("image-upload", {
                  attrs: {
                    component: { imageId: null },
                    add: _vm.addImage,
                    options: _vm.imageOptions
                  }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }