<template>
  <div class="container-fluid">
    <div class="route-point row">
      <span>{{ $t("step") }} {{ component.number }}</span>
      <textarea
        cols="30"
        rows="10"
        class="content custom-scrollbar"
        v-model="content"
      ></textarea>
      <img :src="dragHandlerIcon" class="btn-drag" />
    </div>
  </div>
</template>
<script>
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
export default {
  name: "MapPoint",
  mixins: [ComponentSetterMixin],
  components: {
    ImageUpload,
  },
  computed: {
    content: {
      get() {
        return this.component.content[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
    dragHandlerIcon() {
      return require("@/assets/icons/ic_drag_handler.svg");
    },
  },
};
</script>
<style lang="less">
.route-point {
  margin-top: 8px;
  min-height: 96px;
  position: relative;
  > span {
    height: 16px;
    width: 48px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    align-self: center;
  }
  .content {
    height: 72px;
    // width: 624px;
    width: 550px;
    margin-top: 0;
  }
  > img {
    margin-left: 16px;
  }
}
</style>
