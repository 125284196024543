var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "route-point row" }, [
      _c("span", [
        _vm._v(_vm._s(_vm.$t("step")) + " " + _vm._s(_vm.component.number))
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.content,
            expression: "content"
          }
        ],
        staticClass: "content custom-scrollbar",
        attrs: { cols: "30", rows: "10" },
        domProps: { value: _vm.content },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.content = $event.target.value
          }
        }
      }),
      _c("img", {
        staticClass: "btn-drag",
        attrs: { src: _vm.dragHandlerIcon }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }