<template>
    <div class="content-component row" :class="[component.paragraph.type, {'component-active': isActive}]"
      :style="{order : component.order}" @click="updateCurrentComponent">
        <span class="content-component-count">{{ componentCount }}</span>
        
        <div style="display:flex; width:100%">
            <div   class="image-section column">
            <image-upload v-if="image" class="image-displayed" :component="image" :key="image.id"  :remove="removeImageDetails" :options="options"/>
            <image-upload v-else  :component="{}" :add="newImageDetails" :options="options"/>
        </div>
            <input class="title" type="text" v-model="title" :placeholder="$t('titlePlaceHolder')">
        </div> 
        <span class="legend centered">{{$t(component.paragraph.text)}}</span>
        <button class="btn secondary delete"  @click="deleteComponent(component)">{{$t('delete')}}</button>
</div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'SectionTitleContent',
    mixins:[BackOfficeComponentMixin], 
    props: {
        componentCount: Number,
    },
    methods: {
        setBuilderComponentPropertyValue(value, propertyName = this.options.model, language = null) {
            this.$store.commit('setBuilderComponentPropertyValue',
                { 'component': this.component.paragraph, 'propertyName': propertyName, 'value': value, language: language })
        }
    },
    computed:{
        title:{
            get(){
                return this.component.paragraph.title[this.locale]
            }, 
            set(value)
            {
                this.setBuilderComponentPropertyValue(value, 'title', this.locale)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.content-component {
        background-color: #f6f7fa !important;
        &.component-active {
            background-color: #fff !important;
        }
        &.section-title {
            .legend{
                font-size: 19.64px;
                color: #0a1554;
                font-weight: bold;
            }
        }
}
</style>



