<template>
  <div class="gc-point">
    <!-- <span>{{ $t("step") }} {{ component.number }}</span> -->
    <!-- <textarea
      cols="30"
      rows="10"
      class="content custom-scrollbar"
      v-model="content"
    ></textarea> -->
    <ckeditor
      :editor="editor"
      :placeholder="$t('textPlaceHolder')"
      v-model="content"
      :config="editorConfig"
      class="content custom-scrollbar"
    ></ckeditor>
    <!-- <img :src="dragHandlerIcon" class="btn-drag" /> -->
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import ImageUpload from "@/components/BackOffice/ComponentSetters/ImageUpload.vue";
export default {
  name: "GlobalConnectionsPoint",
  mixins: [BackOfficeComponentMixin],
  components: {
    ImageUpload,
  },
  computed: {
    content: {
      get() {
        return this.component.content[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
    dragHandlerIcon() {
      return require("@/assets/icons/ic_drag_handler.svg");
    },
  },
  methods: {
    setComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
};
</script>
<style lang="less">
.gc-point {
  // margin-top: 8px;
  min-height: 96px;
  position: relative;
  > span {
    height: 16px;
    width: 48px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    align-self: center;
  }
  .content {
    height: 72px;
    max-width: 100px;
    margin-top: 0;
  }
  > img {
    margin-left: 16px;
  }
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    width: 100%;
    height: 100px;
    overflow-y: auto;
  }
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    margin: 0;
    min-width: 321px;
    // min-height: 200px;
  }
}
</style>
