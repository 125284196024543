var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component column",
      class: [
        { "component-active": _vm.isActive },
        _vm.component.paragraph.type
      ],
      style: { order: _vm.component.order },
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c(
        "draggable",
        {
          attrs: { options: _vm.dragOptions, move: _vm.onMove },
          on: { change: _vm.onChange },
          model: {
            value: _vm.points,
            callback: function($$v) {
              _vm.points = $$v
            },
            expression: "points"
          }
        },
        [
          _c("span", { staticClass: "content-component-count" }, [
            _vm._v(_vm._s(_vm.componentCount))
          ]),
          _c(
            "transition-group",
            _vm._l(_vm.points, function(point) {
              return _c("point", { key: point.id, attrs: { component: point } })
            })
          )
        ],
        1
      ),
      _c(
        "button",
        {
          staticClass: "btn secondary round new-point",
          on: { click: _vm.addPoint }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("addMapStepText")) + "\n  ")]
      ),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: {
            click: function($event) {
              _vm.deleteComponent(_vm.component)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("delete")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }