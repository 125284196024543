<template>
  <div
    class="content-component column padd-bottom"
    :class="[{ 'component-active': isActive }, component.paragraph.type]"
    :style="{ order: component.order }"
    @click="updateCurrentComponent"
  >
    <div class="flex-container">
      <div class="flex-child">
        <input
          class="title"
          type="text"
          v-model="title"
          :placeholder="$t('titlePlaceHolder')"
        />
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-child">
        <gc-image-upload
          :component="points[0]"
          :imageExists="isImageExists(points[0])"
          :parentComponent="component"
        />
      </div>

      <div class="flex-child">
        <gc-image-upload
          :component="points[1]"
          :imageExists="isImageExists(points[1])"
          :parentComponent="component"
        />
      </div>
    </div>
    <div class="flex-container">
      <div class="flex-child">
        <div class="mb-4">
        <gc-image-upload
          :component="points[2]"
          :imageExists="isImageExists(points[2])"
          :parentComponent="component"
        />
        </div>
        <point v-model="points" :component="points[2]" />
      </div>

      <div class="flex-child">
        <point v-model="points" :component="points[3]" />
      </div>
    </div>
    <span class="content-component-count">{{ componentCount }}</span>
    <span class="legend centered">{{ $t(component.paragraph.text) }}</span>
    <button class="btn secondary delete" @click="deleteComponent(component)">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import GlobalConnectionsPoint from "@/components/BackOffice/ContentComponents/GlobalConnectionsPoint.vue";
import CheckBox from "@/components/BackOffice/ComponentSetters/CheckBox.vue";
import draggable from "vuedraggable";
import GlobalConnectionsImageUpload from "./GlobalConnectionsImageUpload.vue";
export default {
  name: "GlobalConnectionsComponent",
  mixins: [BackOfficeComponentMixin],
  components: {
    point: GlobalConnectionsPoint,
    draggable,
    CheckBox,
    "gc-image-upload": GlobalConnectionsImageUpload,
  },
  data() {
    return {
      currentRoutePoint: null,
    };
  },
  computed: {
    points: {
      get() {
        return this.component.paragraph.routePoints;
      },
      set(value) {
        this.$store.commit("updateRoutePoints", {
          component: this.component,
          points: value,
        });
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "points",
        ghostClass: "ghost",
        handle: ".btn-drag",
      };
    },
    horizontalMode() {
      return this.component.paragraph.isHorizontalMode;
    },
    content: {
      get() {
        return this.component.paragraph.content[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "content", this.locale);
      },
    },
    title: {
      get() {
        return this.component.paragraph.title[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue(value, "title", this.locale);
      },
    },
  },
  props: {
    componentCount: Number,
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onChange({ moved }) {
      this.$store.dispatch("updateRoutePointOrder", moved.element);
    },
    addImage(imageId) {
      this.$store.commit("addImageIdToRoutePoint", {
        point: this.currentRoutePoint,
        imageId: imageId,
      });
    },
    removeImage(imageId) {
      this.$store.commit("removeImageIdToRoutePoint", {
        point: this.currentRoutePoint,
        imageId: imageId,
      });
    },
    updateImage(oldImgId, newImgId) {
      this.$store.commit("updateImageIdToRoutePoint", {
        point: this.currentRoutePoint,
        oldImgId: oldImgId,
        newImgId: newImgId,
      });
    },
    isImageExists(point) {
      if(!point) return;
      return point.images && point.images[0];
    },
    setComponentPropertyValue(
      value,
      propertyName = this.options.model,
      language = null
    ) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.paragraph,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.padd-bottom{
  padding-bottom: 10px!important;
}
.content-component.global-connections {
  min-height: 189px;
  padding-top: 56px;
  .new-point {
    margin: auto;
  }
  .content-component-count {
    margin-top: -32px;
  }
  .checkbox-setter {
    top: 4px;
    left: 0;
    transform: translate(0, 0);
  }
  > div:nth-child(2) {
    margin-bottom: 8px;
  }
  .ghost {
    box-sizing: border-box;
    max-height: unset;
    border: none;
    margin: inherit;
    margin-top: 8px;
    height: 72px;
    img {
      display: initial;
    }
  }
  .row.centered {
    z-index: 3;
    top: 8px;
    left: 8px;
    transform: translate(0);
    .checkbox-setter {
      max-width: 125px;
    }
  }
  //css added by animesh
  .flex-container {
    // margin-top: 10px;
    display: flex;
    // align-items: center;
    // justify-content: center;
    .flex-child {
      flex: 1;
      .title {
        min-width: 657px;
        margin-top: 0px;
        margin-left: 0px;
      }
    }
    .flex-child:first-child {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .image-section {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin: auto;
      width: 320px;
      height: auto;
    }
  }
}
</style>
