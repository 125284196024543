var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component column",
      class: [
        _vm.component.paragraph.type,
        { "component-active": _vm.isActive }
      ],
      style: _vm.objectStyles,
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c("span", { staticClass: "content-component-count" }, [
        _vm._v(_vm._s(_vm.componentCount))
      ]),
      _c("check-box", {
        staticClass: "centered",
        attrs: {
          component: _vm.component,
          options: { text: "horizontal", model: "isHorizontalMode" }
        }
      }),
      _c(
        "section",
        {
          staticClass: "images-section",
          class: _vm.component.paragraph.isHorizontalMode ? "row" : "column"
        },
        [
          _vm._l(_vm.component.paragraph.images, function(image, index) {
            return _c(
              "div",
              { key: index, staticClass: "image-section" },
              [
                _c("image-upload", {
                  staticClass: "image-displayed",
                  attrs: {
                    component: image,
                    remove: _vm.removeImageDetails,
                    options: _vm.options
                  }
                })
              ],
              1
            )
          }),
          _vm.component.paragraph.images.length < 1
            ? _c(
                "div",
                { staticClass: "image-section column" },
                [
                  _c("image-upload", {
                    attrs: {
                      component: { imageId: null },
                      add: _vm.newImageDetails,
                      options: _vm.options
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.paragraph.text)))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: {
            click: function($event) {
              _vm.deleteComponent(_vm.component)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("delete")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }